import oidcProvider from '@luigi-project/plugin-auth-oidc';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { getAdvisorPermissionsService } from 'services/api/advisorPermissions';
import { getEnvConfig } from 'services/envConfig';
import { getAppInsights } from 'services/helpers/getAppInsights';

import { orchestrator } from '../orchestrator';
import { getAdvisorService } from '../services/api/advisor';
import { UserRoles, getUserService } from '../services/api/user';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import { initFeatureFlag } from './featureFlag';
import { getMicroApplicationList } from './navigation';

import type { AccessToken, Advisor, AuthData, InternalUser, UserData } from '../type/advisor';
import type { ChangeUser } from 'utils/navigation';

const { appInsights } = getAppInsights();

const getIsUserContext = (tokenRoles: string[]) =>
  tokenRoles?.length === 1 && tokenRoles[0] === 'user';

const logoutIfNoToken = (accessToken: string) => {
  if (!accessToken) {
    orchestrator().auth().logout();
  }
};

const setAdvisorData = (
  token: AccessToken,
  isUserContext: boolean,
  advisorAllInfo: Advisor,
  advisorId: string,
) => {
  const userDataLocalStore = getSessionStore<UserData>(sessionStoreKeys.userData);

  if (token.context) {
    userDataLocalStore.extendItem({ parentUserId: token.context?.preferred_username });
  }

  if (!isUserContext) {
    userDataLocalStore.extendItem({ advisor: advisorAllInfo });
  }

  userDataLocalStore.extendItem({ advisorNumber: advisorId });
};

const getAdvisorId = (token: AccessToken) =>
  token.context ? token.context?.preferred_username : token.preferred_username;

async function getSetAdvisor(
  type: string,
  advisorService: ReturnType<typeof getAdvisorService>,
  token: AccessToken,
  userDataStore: ReturnType<typeof getSessionStore>,
  isUserContext: boolean,
) {
  const advisor: Advisor | InternalUser =
    type !== UserRoles.internalUser
      ? await advisorService.getAdvisor()
      : {
          id: token.context?.preferred_username || token.preferred_username,
          firstName: token.context?.given_name || token.given_name,
          lastName: token.context?.family_name || token.family_name,
          tenantId: 1,
          urlList: {
            homepage: '/',
            dreamJob: '/dream-job',
            teamBuilding: '/team-building',
            dreamJobWhatsapp: '/dream-job-whatsapp',
          },
        };

  userDataStore.extendItem(isUserContext ? { advisor } : { assistant: advisor });

  if (type === UserRoles.internalUser) {
    userDataStore.extendItem({ advisor });
    if (token.context) {
      userDataStore.extendItem({
        parentUserId: token.context?.preferred_username,
        assistant: {
          id: token.preferred_username,
          firstName: token.given_name,
          lastName: token.family_name,
          tenantId: 3,
          urlList: {
            homepage: '/',
            dreamJob: '/dream-job',
            teamBuilding: '/team-building',
            dreamJobWhatsapp: '/dream-job-whatsapp',
          },
        },
      });
    }
  }
}

export const onAuthSuccessful = async (authData: AuthData) => {
  const userDataStore = getSessionStore(sessionStoreKeys.userData);
  const userService = getUserService();
  let isUserContext = false;
  try {
    const user = await userService.getUser();
    const { type, supervisorId } = user;
    userDataStore.setItem({ role: type, parentUserId: supervisorId });

    const advisorPermissionsService = getAdvisorPermissionsService();
    const accessToken = authData?.accessToken;

    logoutIfNoToken(accessToken);

    const token: AccessToken = jwtDecode(JSON.stringify(accessToken));
    const tokenRoles = token.resource_access['app-web-vp-digital'].roles;
    isUserContext = getIsUserContext(tokenRoles);

    const advisorService = getAdvisorService(supervisorId);

    if (type === UserRoles.assistant) {
      const assistant = await advisorService.getUser();
      userDataStore.extendItem({ assistant });
    } else {
      const hasAnalysisPermission = await advisorPermissionsService.hasAnalysisPermission();

      if (!hasAnalysisPermission) {
        console.warn('no analysis permission');
        orchestrator().auth().logout();
        return;
      }

      await getSetAdvisor(type, advisorService, token, userDataStore, isUserContext);
    }

    const advisorId = getAdvisorId(token);

    const userPermissions = await advisorPermissionsService.getUserPermissions();
    userDataStore.extendItem({ userPermissions });

    const userData = userDataStore.getItem() as UserData;
    const assistantWithNoAdvisorSelected =
      (userData?.role === UserRoles.assistant && !userData?.parentUserId) ||
      (userData?.role === UserRoles.external &&
        !userData?.advisorNumber &&
        !userData?.parentUserId);

    if (assistantWithNoAdvisorSelected) {
      const advisorService2 = getAdvisorService(token.context?.preferred_username);

      const advisorAllInfo = await advisorService2.getAdvisor();

      const hasAnalysisPermission = await advisorPermissionsService.hasAnalysisPermission();

      if (!hasAnalysisPermission) {
        orchestrator().auth().logout();
        return;
      }

      setAdvisorData(token, isUserContext, advisorAllInfo, advisorId);
    }

    if (token.context) {
      userDataStore.extendItem({
        assistant: user,
      });
    }

    await initFeatureFlag();
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      appInsights.trackException({ exception: err as Error, severityLevel: 3 });
    }
    orchestrator().auth().logout();
  }
};

const onLogout = () => {
  window.userlike?.userlikeCloseButton();
  sessionStorage.clear();
  getSessionStore(sessionStoreKeys.userData).removeItem();
};

export const checkIsAutoLogin = (pathName: string) => {
  const coachReportPatern = /coach-report\/report\/[a-f0-9]+/i;
  return !(pathName && coachReportPatern.test(pathName));
};

export const getAuth = (changeUserContext?: ChangeUser | null) => ({
  use: 'openIdConnect',
  storage: 'sessionStorage',

  openIdConnect: {
    // Orchestrator
    idpProvider: oidcProvider,
    authority: `${getEnvConfig().authentication.dvagAuth}/realms/${
      getEnvConfig().authentication.dvagRealm
    }`,
    logoutUrl: `${getEnvConfig().authentication.dvagAuth}/realms/${
      getEnvConfig().authentication.dvagRealm
    }/protocol/openid-connect/logout`,
    automaticSilentRenew: false,

    // OpenID Connect
    scope: 'openid profile email context_profile',
    client_id: getEnvConfig().authentication.dvagClientId,
    response_type: 'code',
    response_mode: 'fragment',
    extraQueryParams: changeUserContext
      ? {
          hd: 'domain.com',
          kc_action: 'CONTEXT_CHANGE',
          ...(changeUserContext.userId && { context_username: changeUserContext.userId }),
        }
      : {},
    // We are doing the check below because  /login is accessible even when logged in

    redirect_uri: window.location.pathname.includes(`/${getMicroApplicationList().login.id}`)
      ? window.location.origin
      : window.location.href,

    post_logout_redirect_uri: window.location.origin,
  },

  events: {
    onAuthExpired: () => false,
    // won't work if we directly provide the onAuthSuccessful async function
    // like this onAuthSuccessful:onAuthSuccessful
    onAuthSuccessful: <T>(_settings: T, authData: AuthData) => onAuthSuccessful(authData),
    onLogout,
  },

  disableAutoLogin: checkIsAutoLogin(window.location.pathname),
});
