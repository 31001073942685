import { getApiUrls } from '@dvag/dfs-constant-config/api-urls';
import { GrowthBook, setPolyfills } from '@growthbook/growthbook';

import { orchestrator } from 'orchestrator';
import { getEnvConfig } from 'services/envConfig';

import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import type { UserData } from '../type/advisor';
import type { FeatureDefinition } from '@growthbook/growthbook';

type FeatureFlag = {
  gb: GrowthBook<Record<string, unknown>> | undefined;
  init: (accessToken: string) => Promise<void>;
  getFeatures: () => Record<string, FeatureDefinition<unknown>> | undefined;
};
type FetchInit = {
  headers?: Record<string, string>;
};
const attachHeaders = (accessToken: string) =>
  setPolyfills({
    fetch(input: URL, init: FetchInit = {}) {
      const url = input.toString().slice(0, -1);
      const headers = {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      return window.fetch(url, {
        ...init,
        headers,
      });
    },
  });

export const featureFlag: FeatureFlag = {
  gb: undefined,
  init: async (accessToken: string) => {
    const { apiHost } = getApiUrls(getEnvConfig()?.env || 'dev').featureFlag;
    attachHeaders(accessToken);
    featureFlag.gb = new GrowthBook({
      apiHost,
      clientKey: '/',
      enableDevMode: true,
    });
    await featureFlag.gb.init({ skipCache: true });
    featureFlag.gb.setRenderer(() => {
      orchestrator().configChanged('navigation.nodes');
    });
    orchestrator().configChanged('navigation.nodes');
  },
  getFeatures: () => featureFlag.gb?.getFeatures(),
};

export const initFeatureFlag = async () => {
  const userData = getSessionStore<UserData>(sessionStoreKeys.userData).getItem();
  const userId = userData?.advisor?.id;
  if (userId) {
    const accessToken = orchestrator().auth().store.getAuthData()?.accessToken;
    await featureFlag.init(accessToken);
  }
};
