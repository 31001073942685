import Annotation from '@dvag/dfs-annotation-widget';
import { appNames } from '@dvag/dfs-constant-config';
import DocumentUpload from '@dvag/dfs-document-upload';
import Navigation from '@dvag/dfs-navigation';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { defaultNS, i18next } from 'config/i18n';
import { getEnvConfig } from 'services/envConfig';

import { showContextSelectionModal } from '../component/ContextSelection/ContextSelectionModal';
import { preventUnload } from '../config/communication';
import { featureFlag } from '../config/featureFlag';
import { getMicroApplicationList } from '../config/navigation';
import { errorToast, successToast } from '../notification';
import { orchestrator } from '../orchestrator';
import { UserRoles } from '../services/api/user';
import { getHouseholdId } from '../services/helpers/getHouseholdId';
import { isSimulationRoute } from '../services/helpers/routeHelper';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';
import { changeUserContext, navigate, navigationRequest } from '../utils/navigation';

import { getBottomLinks } from './getBottomLinks';
import { getInitials, getName } from './getStorageInfo';
import { markVisitedSection } from './markVisitedSection';
import { MenuItemList } from './MenuItemList';
import { usePermission } from './permission/usePermission';
import { useCurrentNode } from './useCurrentNode';
import { useCurrentRoute } from './useCurrentRoute';
import { useGetNavigationItems } from './useGetNavigationItems';
import useGetPersonIdList from './useGetPersonIdList';

import type { AccessToken } from '../type/advisor';
import type { UploadEventType } from '@dvag/dfs-document-upload/dist/cjs/src/service/hook/useDocumentList/type';

const logout = () => {
  const isDirtySessionStore = getSessionStore(sessionStoreKeys.microappIsDirty);
  if (isDirtySessionStore.getItem() === true) {
    navigationRequest({
      url: `/${appNames.login}/logout`,
      nextUrl: undefined,
    });
  } else {
    orchestrator().auth().logout();
  }
};

const handleUploadEvent = (detail: UploadEventType) =>
  orchestrator().customMessages().sendToAll({
    id: 'document.upload.event',
    detail,
  });

const isSalesforceWishesAndGoals = (currentRoute: string | undefined) =>
  currentRoute?.includes('wuensche-und-ziele') && currentRoute?.endsWith('salesforce');

const WrappedNavigation = () => {
  const currentNode = useCurrentNode();
  const currentRoute = useCurrentRoute();
  const permissionMap = usePermission();
  const isSalesforceRoute = isSalesforceWishesAndGoals(currentRoute);

  const isAddFavoritesPage =
    currentRoute?.replace(`${getHouseholdId(currentRoute)}`, ':householdId') ===
    '/berater/haushalt/:householdId/favoriten';

  const name = getName();
  const initials = getInitials();

  const fetchPersonListSessionStore = getSessionStore(sessionStoreKeys.fetchPersonList);
  const fetchPersonList = fetchPersonListSessionStore.getItem()?.toString();

  const householdId = getHouseholdId(currentRoute);

  const {
    data: { personIdList },
    isLoading,
  } = useGetPersonIdList(householdId, fetchPersonList);

  const routeItems = useGetNavigationItems({ householdId });

  useEffect(() => {
    if (householdId && personIdList.length) {
      personIdList.forEach((id) => markVisitedSection(currentRoute, householdId, id));
    }
  }, [currentRoute, householdId, personIdList]);

  useEffect(() => {
    if (currentRoute === `/${appNames.login}/logout`) {
      window.removeEventListener('beforeunload', preventUnload);
      orchestrator().auth().logout();
    }
  }, [currentRoute]);

  const accessToken = orchestrator().auth().store.getAuthData()?.accessToken;
  const parentUserId = currentNode?.context?.userData?.parentUserId || '';
  const advisorNumber = currentNode?.context?.userData?.advisorNumber;
  const isAssistant = currentNode?.context?.userData?.role === UserRoles.assistant;
  const isAdvisor = currentNode?.context?.userData?.role === UserRoles.external;
  let isUserContext = false;
  if (accessToken) {
    const token: AccessToken = jwtDecode(JSON.stringify(accessToken));
    const tokenRoles = token.resource_access['app-web-vp-digital'].roles;
    isUserContext = tokenRoles?.length === 1 && tokenRoles[0] === 'user';
  }

  const tenantId =
    currentNode?.context?.userData?.advisor?.tenantId ||
    currentNode?.context?.userData?.assistant?.tenant?.id;

  const isInternalUser = currentNode?.context?.userData?.role === UserRoles.internalUser;

  const isSelectingAdvisor =
    (isAssistant && !parentUserId) || (isAdvisor && !parentUserId && !advisorNumber);

  const showAdvisorSelection =
    (isAssistant && currentNode?.viewGroup !== getMicroApplicationList().login.id) ||
    (isAdvisor &&
      currentNode?.viewGroup !== getMicroApplicationList().login.id &&
      !isUserContext) ||
    isInternalUser;

  const navigationItems = !accessToken ? [] : routeItems;
  const bottomLinks = getBottomLinks();

  const routeList = [
    'analyse',
    'analysis',
    'advisory',
    'beratung',
    'dashboard',
    'uebersicht',
    'teamaufbau',
    'weiterempfehlung',
    'empfehlungprasentation',
  ];
  const isAnalysisOrAdvisoryContext = routeList.some((item) => currentRoute?.includes(item));

  const showWidget =
    !currentNode?.isExternal &&
    householdId &&
    isAnalysisOrAdvisoryContext &&
    !isSimulationRoute(window.location.pathname);

  const showCartWidget = !!(householdId && permissionMap?.canAccessShoppingCart);
  const showFavorites = permissionMap.canAccessFavorites;
  const showUserMenu =
    featureFlag.gb?.isOn('feature.advisor.settings') && permissionMap.canAccessUserSettings;

  const getLogoRoute = () => {
    if (isSelectingAdvisor || !accessToken) return '';
    return householdId
      ? `/${appNames.advisor}/haushalt/${householdId}/uebersicht`
      : `/${appNames.advisor}/haushalt`;
  };

  const getSettingsRoute = () =>
    householdId
      ? `/${appNames.advisor}/haushalt/${householdId}/einstellungen`
      : `/${appNames.advisor}/einstellungen`;

  const onSettingsSelection = () => {
    navigate(getSettingsRoute());
  };

  const onAvatarSelection = useCallback(() => {
    if (showAdvisorSelection) {
      changeUserContext();
    }
  }, [showAdvisorSelection]);

  const userMenuText = {
    changeAdvisor: i18next.t('userMenu.changeAdvisor'),
    settings: i18next.t('userMenu.settings'),
    userAccount: i18next.t('userMenu.userAccount'),
    companyPresentation: i18next.t('userMenu.companyPresentation'),
    impersonation: i18next.t('contextSelection.userMenuImpersonate'),
  };

  const [isFirstLoading, setIsFirstLoading] = useState(
    !!getHouseholdId(window.location.pathname) && isLoading,
  );

  const [prevIsLoading, setPrevIsLoading] = useState(true);

  if (isLoading !== prevIsLoading) {
    if (!isLoading) setIsFirstLoading(false);
    setPrevIsLoading(isLoading);
  }

  const showImpersonation = usePermission().canAccessInternalRole && isInternalUser;

  if (currentNode && !isFirstLoading && !isSalesforceRoute) {
    return (
      <I18nextProvider i18n={i18next} defaultNS={defaultNS}>
        <Navigation
          accessToken={accessToken}
          onLogoSelection={() => navigate(getLogoRoute())}
          onAvatarSelection={onAvatarSelection}
          signOutItem={{
            text: i18next.t('navigationItems.signOut'),
            action: logout,
          }}
          tenantId={tenantId}
          isSelectingAdvisor={isSelectingAdvisor}
          navigationItems={navigationItems}
          bottomLinks={bottomLinks}
          onSettingsSelection={onSettingsSelection}
          widgetList={
            <>
              {householdId && showWidget && permissionMap?.canUploadDocument && (
                <DocumentUpload
                  env={getEnvConfig().env}
                  householdId={householdId}
                  handleUploadEvent={handleUploadEvent}
                  successToast={successToast}
                  errorToast={errorToast}
                  isHidden={householdId && showWidget && permissionMap?.canUploadDocument}
                />
              )}
              {showWidget && (
                <Annotation
                  env={getEnvConfig().env}
                  householdId={householdId}
                  currentRoute={currentRoute}
                  successToast={successToast}
                  errorToast={errorToast}
                  isHidden={showWidget}
                />
              )}
            </>
          }
          menuItemList={
            <MenuItemList
              householdId={householdId}
              isFavoritesHidden={!showFavorites || !accessToken || isSelectingAdvisor}
              isFavoritesDisabled={!householdId || isAddFavoritesPage}
              isShoppingCartHidden={!showCartWidget}
            />
          }
          isLoggedIn={!!accessToken}
          showAdvisorSelection={showAdvisorSelection}
          initials={initials}
          showUserMenu={showUserMenu}
          name={name}
          userMenuText={userMenuText}
          onSetContextSelection={showContextSelectionModal}
          canAccessContextSelection={showImpersonation}
        />
      </I18nextProvider>
    );
  }

  return null;
};

export default WrappedNavigation;
