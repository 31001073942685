import { GraphQLClient } from 'graphql-request';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers'],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type ConfigSchema = {
  __typename?: 'ConfigSchema';
  pages: Array<PageDto>;
  settings: Array<SettingDto>;
};

export type ConfigSchemaPagesArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PageDto = {
  __typename?: 'PageDto';
  aliases: Array<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  featureFlags: Array<Scalars['String']['output']>;
  isDefaultFavorite?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orderPreviousPage?: Maybe<Scalars['String']['output']>;
  permissions: Array<Scalars['String']['output']>;
  type: Array<Type>;
};

export type Query = {
  __typename?: 'Query';
  config: ConfigSchema;
};

export type SettingDto = {
  __typename?: 'SettingDto';
  code: Scalars['String']['output'];
  default: Array<Scalars['String']['output']>;
  isAllowedMultiple: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
};

export enum Type {
  BurgerMenu = 'BURGER_MENU',
  BurgerMenuSearch = 'BURGER_MENU_SEARCH',
  Favorite = 'FAVORITE',
}

export type GetConfigBurgerMenuQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetConfigBurgerMenuQuery = {
  __typename?: 'Query';
  config: {
    __typename?: 'ConfigSchema';
    pages: Array<{
      __typename?: 'PageDto';
      code: string;
      featureFlags: Array<string>;
      permissions: Array<string>;
      aliases: Array<string>;
      name: string;
      type: Array<Type>;
    }>;
  };
};

export const GetConfigBurgerMenuDocument = `
    query getConfigBurgerMenu($type: String) {
  config {
    pages(type: $type) {
      code
      featureFlags
      permissions
      aliases
      name
      type
    }
  }
}
    `;
export const useGetConfigBurgerMenuQuery = <TData = GetConfigBurgerMenuQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetConfigBurgerMenuQueryVariables,
  options?: UseQueryOptions<GetConfigBurgerMenuQuery, TError, TData>,
  headers?: RequestInit['headers'],
) =>
  useQuery<GetConfigBurgerMenuQuery, TError, TData>(
    variables === undefined ? ['getConfigBurgerMenu'] : ['getConfigBurgerMenu', variables],
    fetcher<GetConfigBurgerMenuQuery, GetConfigBurgerMenuQueryVariables>(
      client,
      GetConfigBurgerMenuDocument,
      variables,
      headers,
    ),
    options,
  );
